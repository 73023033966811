import React, {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {Home} from "./Pages/Home/Home";
import {Projects} from "./Pages/Projects/Projects";
import {Services} from "./Pages/Services/Services";
import {About} from "./Pages/About/About";
import {Vacancies} from "./Pages/Vacancies/Vacancies";
import {Blog} from "./Pages/Blog/Blog";
import {Contacts} from "./Pages/Contacts/Contacts";

const titles = {
    '/home': 'ДругойКод',
    '/about': 'ДругойКод | О нас',
    '/projects': 'ДругойКод | Наши проекты',
    '/services': 'ДругойКод | Услуги',
    // '/vacancies': 'ДругойКод | Вакансии',
    // '/blog': 'ДругойКод | Блог',
    '/contact': 'ДругойКод | Контакты',

}
export const App = () => {

    const location = useLocation()
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/projects" element={<Projects/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/about" element={<About/>}/>
            {/*<Route path="/vacancies" element={<Vacancies/>}/>*/}
            {/*<Route path="/blog" element={<Blog/>}/>*/}
            <Route path="/contact" element={<Contacts/>}/>
        </Routes>
    );
};
