import React, { useState } from "react";
import logo from "../../images/Logo.svg";
import burgerIcon from "../../images/BurgerIcon.svg";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.scss";
import { BriefModal } from "../BriefModal/BriefModal";


export const Header = () => {
  const [menu, setMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <header className={styles.header}>
        <div
          className={menu ? styles.header_menu_bg : styles.header_menuDisabled}
          onClick={() => setMenu(!menu)}
        ></div>
        <Link className={styles.header_logo} to="/">
          <img src={logo} alt="" /> <h1>OTHER CODE</h1>
        </Link>
        <div className={styles.header_links_wrapper}>
          <button
            onClick={() => setMenu(!menu)}
            className={styles.header_menuButton}
          >
            {!menu ? <img src={burgerIcon} alt="" /> : <span>&times;</span>}
            <p>МЕНЮ</p>
          </button>
          <section
            className={menu ? styles.header_menu_active : styles.header_menu}
          >
            <NavLink
              onClick={() => setMenu(false)}
              to="/"
              className={styles.header_navLink}
            >
              Главная
            </NavLink>
            {/*<NavLink*/}
            {/*  onClick={() => setMenu(false)}*/}
            {/*  className={styles.header_navLink}*/}
            {/*  to="/projects"*/}
            {/*>*/}
            {/*  Проекты*/}
            {/*</NavLink>*/}
            <NavLink
              onClick={() => setMenu(false)}
              className={styles.header_navLink}
              to="/services"
            >
              Услуги
            </NavLink>
            <NavLink
              onClick={() => setMenu(false)}
              className={styles.header_navLink}
              to="/about"
            >
              О нас
            </NavLink>
            {/*<NavLink*/}
            {/*  onClick={() => setMenu(false)}*/}
            {/*  className={styles.header_navLink}*/}
            {/*  to="/vacancies"*/}
            {/*>*/}
            {/*  Вакансии*/}
            {/*</NavLink>*/}
            {/*<NavLink*/}
            {/*  onClick={() => setMenu(false)}*/}
            {/*  className={styles.header_navLink}*/}
            {/*  to="/blog"*/}
            {/*>*/}
            {/*  Блог*/}
            {/*</NavLink>*/}
            <NavLink
              onClick={() => setMenu(false)}
              className={styles.header_navLink}
              to="/contact"
            >
              Контакты
            </NavLink>
          </section>
          <button
            onClick={() => setIsModalOpen(true)}
            className={styles.header_lastLink}
          >
            Обсудить задачу
          </button>
        </div>
      </header>
      {/* Модальное окно */}
      <BriefModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};
