import React, { useState } from "react";
import Modal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import styles from "./BriefModal.module.scss";

export const BriefModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const { handleSubmit, register, control, watch, formState: { errors }, trigger } = useForm();
  const stepsCount = 4;

  const budget = watch("budget");
  const deadline = watch("deadline");
  const agreement = watch("agreement");

  const sendToTelegram = async (data) => {
    const token = "7536059268:AAHLkS4HDKEI4HaGj8i6FpnCkuLs3_Xm1Fo";
    const chatId = "528748744";
    const message = `
      📋 *Новый бриф от клиента:*\n
      👤 Имя: ${data.name}
      ☎️ Телефон: ${data.phone}
      ✉️ Email: ${data.email}
      📖 О проекте: ${data.projectInfo}
      🗂️ Направление: ${data.direction}
      💰 Бюджет: ${data.budget === "custom" ? data.customBudget : data.budget}
      ⏳ Сроки: ${data.deadline === "custom" ? data.customDeadline : data.deadline}
    `;

    try {
      const response = await fetch(
        `https://api.telegram.org/bot${token}/sendMessage`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
            parse_mode: "Markdown",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Не удалось отправить сообщение в Telegram");
      }

      alert("Бриф успешно отправлен!");
      isOpen = false
    } catch (error) {
      console.error(error);
      alert("Ошибка отправки данных в Telegram.");
    }
  };

  const onSubmit = async (data) => {
    await sendToTelegram(data);
    onClose();
  };

  const nextStep = async () => {
    let isValid = false;
    switch (step) {
      case 1:
        isValid = await trigger(["name", "phone", "email", "projectInfo"]);
        break;
      case 2:
        isValid = await trigger("direction");
        break;
      case 3:
        isValid = await trigger(["budget", "deadline"]);
        if (budget === "custom") isValid = isValid && await trigger("customBudget");
        if (deadline === "custom") isValid = isValid && await trigger("customDeadline");
        break;
      default:
        break;
    }
    if (isValid) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.modalHeader}>
          <h2 className={styles.home_h3}>Расскажите нам о проекте</h2>
          <button type="button" onClick={onClose} className={styles.closeButton}>
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>
          {step === 1 && (
            <div>
              {/* <h4 className={styles.h4}>Расскажите нам о проекте</h4> */}
              <section>
                <div className={styles.inputWrapper}>
                  <input
                    {...register("name", { required: "Это поле обязательно" })}
                    placeholder="Имя*"
                    className={`${styles.input} ${errors.name ? styles.errorInput : ''}`}
                  />
                  {errors.name && <p className={styles.error}></p>}
                </div>
                <label className={styles.phoneLabel}>
                  <input
                    type="tel"
                    placeholder="+7*"
                    {...register("phone", { required: "Это поле обязательно" })}
                    className={`${styles.input} ${errors.phone ? styles.errorInput : ''}`}
                  />
                </label>
                {errors.phone && <p className={styles.error}></p>}
              </section>
              <div className={styles.inputWrapper}>
                <input
                  {...register("email", { required: "Это поле обязательно" })}
                  placeholder="E-mail*"
                  className={`${styles.input} ${errors.email ? styles.errorInput : ''}`}
                />
                {errors.email && <p className={styles.error}></p>}
              </div>
              <div className={styles.inputWrapper}>
                <textarea
                  {...register("projectInfo", { required: "Это поле обязательно" })}
                  placeholder="Опишите задачу*"
                  className={`${styles.textarea} ${errors.projectInfo ? styles.errorInput : ''}`}
                />
                {errors.projectInfo && <p className={styles.error}></p>}
              </div>
            </div>
          )}
          {step === 2 && (
            <div>
              <h4 className={styles.home_h4}>Выбор направления</h4>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Веб-разработка"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Веб-разработка</p>
              </label>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Blockchain-решения"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Blockchain-решения</p>
              </label>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Сопровождение проектов"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Сопровождение проектов</p>
              </label>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Мобильная разработка"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Мобильная разработка</p>
              </label>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Продвижение и реклама"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Продвижение и реклама</p>
              </label>
              <label className={styles.home_projects_form_label}>
                <input
                  type="radio"
                  {...register("direction", { required: "Это поле обязательно" })}
                  value="Комплексный проект"
                  className={styles.home_projects_form_label_input}
                />
                <p className={styles.home_projects_form_label_text}>Комплексный проект</p>
              </label>
              {errors.direction && <p className={styles.error}>{errors.direction.message}</p>}
            </div>
          )}
          {step === 3 && (
            <div className={styles.budgetDeadlineContainer}>
              <div>
                <h4 className={styles.home_h4}>Бюджет</h4>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("budget", { required: "Это поле обязательно" })}
                    value="до 50 тыс.руб"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>до 50 тыс.руб</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("budget", { required: "Это поле обязательно" })}
                    value="до 100 тыс.руб"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>до 100 тыс.руб</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("budget", { required: "Это поле обязательно" })}
                    value="до 200 тыс.руб"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>до 200 тыс.руб</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("budget", { required: "Это поле обязательно" })}
                    value="custom"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>Другое</p>
                </label>
                {budget === "custom" && (
                  <Controller
                    control={control}
                    name="customBudget"
                    render={({ field }) => (
                      <div className={styles.inputWrapper}>
                        <input
                          type="text"
                          {...field}
                          placeholder="Введите сумму*"
                          className={`${styles.input} ${errors.customBudget ? styles.errorInput : ''}`}
                        />
                        {errors.customBudget && <p className={styles.error}>{errors.customBudget.message}</p>}
                      </div>
                    )}
                  />
                )}
                {errors.budget && <p className={styles.error}>{errors.budget.message}</p>}
              </div>
              <div>
                <h4 className={styles.home_h4}>Сроки</h4>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("deadline", { required: "Это поле обязательно" })}
                    value="месяц"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>1 месяц</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("deadline", { required: "Это поле обязательно" })}
                    value="3 месяца"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>3 месяца</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("deadline", { required: "Это поле обязательно" })}
                    value="полгода"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>6 месяцев</p>
                </label>
                <label className={styles.home_projects_form_label}>
                  <input
                    type="radio"
                    {...register("deadline", { required: "Это поле обязательно" })}
                    value="custom"
                    className={styles.home_projects_form_label_input}
                  />
                  <p className={styles.home_projects_form_label_text}>Другое</p>
                </label>
                {deadline === "custom" && (
                  <Controller
                    control={control}
                    name="customDeadline"
                    render={({ field }) => (
                      <div className={styles.inputWrapper}>
                        <input
                          type="text"
                          {...field}
                          placeholder="Введите сроки*"
                          className={`${styles.input} ${errors.customDeadline ? styles.errorInput : ''}`}
                        />
                        {errors.customDeadline && <p className={styles.error}>{errors.customDeadline.message}</p>}
                      </div>
                    )}
                  />
                )}
                {errors.deadline && <p className={styles.error}>{errors.deadline.message}</p>}
              </div>
            </div>
          )}
          {step === 4 && (
            <div>
              <h4 className={styles.home_h4}>Проверьте введенные данные</h4>
              <p><strong>Имя:</strong> {watch("name")}</p>
              <p><strong>Телефон:</strong> {watch("phone")}</p>
              <p><strong>Email:</strong> {watch("email")}</p>
              <p><strong>О проекте:</strong> {watch("projectInfo")}</p>
              <p><strong>Направление:</strong> {watch("direction")}</p>
              <p><strong>Бюджет:</strong> {budget === "custom" ? watch("customBudget") : watch("budget")}</p>
              <p><strong>Сроки:</strong> {deadline === "custom" ? watch("customDeadline") : watch("deadline")}</p>
              <label className={styles.home_projects_form_confirmLabel}>
                <input
                  type="checkbox"
                  {...register("agreement", { required: "Это поле обязательно" })}
                />
                <p>Отправляя форму, вы соглашаетесь с политикой обработки персональных данных</p>
              </label>
              {errors.agreement && <p className={styles.error}>{errors.agreement.message}</p>}
            </div>
          )}
        </div>
        <div className={styles.modalFooter}>
          <button type="button" onClick={prevStep} disabled={step === 1} className={styles.home_button}>
            Назад
          </button>
          {step < stepsCount ? (
            <button type="button" onClick={nextStep} className={styles.home_button}>
              Далее
            </button>
          ) : (
            <button type="submit" disabled={!agreement} className={styles.home_button}>Отправить</button>
          )}
        </div>
      </form>
    </Modal>
  );
};
